.manage-schedule-container{
    
    .manage-schedule-title{
        text-transform: uppercase;
        margin-top: 15px;
        margin-bottom: 15px;
        text-align: center;
        font-size: 17px;
        font-weight: 600;
    }
   
          
    .pick-hour-container{
        padding: 20px;
        margin-top: 10px;
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        .btn-schedule{
            padding: 5px 15px;
            border: 1px solid green;
            &.active{
                background: orange;
            }
        }
    }
    .btn-save-schedule{
        margin-top: 20px;
    }
     
   
}