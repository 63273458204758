
.profile-doctor-container{
    .intro-doctor{
        display: flex;
        .content-left{
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            width: 80px;
            height: 80px;
            border-radius: 50%;
        }
        .content-right{
            width: calc(100% - 80px);
            display: flex;
            flex-direction: column;
            padding-left: 10px;
         
            .up{
                font-size: 20px;
                font-weight: 600;
            }
            .down{
                text-align: justify;
            }
        }
    
    }
}