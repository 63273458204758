.manage-doctor-container{
    padding: 5px;
    .manage-doctor-title{
        text-align: center;
        text-transform: uppercase;
        margin: 15px 0;
        font-size: 20px;
        font-weight: 600;
    }
    .save-content-doctor{
        margin-top: 20px;
        margin-bottom: 30px;
        border-radius: 3px;
        outline: none;
        border:none;
        padding: 5px;
        background: orange;
        cursor: pointer;
        &:hover{
            opacity: 0.9;
        }
    }
    .create-content-doctor{
        margin-top: 20px;
        margin-bottom: 30px;
        border-radius: 3px;
        outline: none;
        border:none;
        padding: 5px;
        background: rgb(0, 140, 255);
        color: white;
        cursor: pointer;
        &:hover{
            opacity: 0.9;
        }
    }
    .more-infor{
        display: flex;
        gap:15px;
        padding-bottom: 15px;
        .content-left{
            width: 35%;
        }
        .content-right{
            width: 65%;
        }
    }
    .manage-doctor-editor{
        margin-top: 10px;
    }
}