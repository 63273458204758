.doctor-schedule-container{
    border-right: 1px solid #ddd;
    height: 100%;
    .all-schedule{
        select{
            border-bottom: 1px solid #ddd;
            border-top: none ;
            border-right: none ;
            border-left: none ;
            outline: none;
            cursor: pointer;
            padding: 10px 0;
            color:  #337ab7;
            font-weight: 600;

            option{
                cursor: pointer;
            }

        }
        
    }
    .all-available-time{
        .text-calendar{
           color: #333;
           margin: 10px 0;
           span{
                text-transform: uppercase;
                margin-left: 5px;
           }
        }
        .time-content{
          
            .time-content-btns{
                display: flex;
                gap:15px;
                flex-wrap: wrap;
                button{
                    padding: 8px 10px;
                    border: none;
                    outline: none;
                    background-color: #fff04b;
                    border-radius: 3px;
                    font-weight: 600;
                    color: #333;
                    &:hover{
                        background-color: #45c3d2;
                        color: white;
                    }
    
                }
                .btn-vie{
                    min-width: 120px;
                }
                .btn-en{
                    min-width: 160px;
    
                }
                .no-schedule{
                    // font-style: italic;
                    font-size: 14px;
                }
            }
            .book-free{
                margin-top: 10px
            }
         
           
        }
    }
}