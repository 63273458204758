.slick-prev {

    left: -10 !important;
    z-index: 1000 !important;
    width: 44px !important;
    height: 48px !important;
    background: white !important;
    border: 2px solid #d7d7d7 !important;

    &:hover {
        background: #ebe4e4 !important;
    }
}

.slick-prev:before,
.slick-next:before {

    font-family: FontAweSome !important;
    color: red !important;
}

.slick-next {
    right: -10 !important;
    width: 44px !important;
    height: 48px !important;
    z-index: 1000 !important;
    background: white !important;
    border: 2px solid #d7d7d7 !important;

    &:hover {
        background: #ebe4e4 !important;
    }
}
.slick-disabled{
    display: none !important;
}



.section-share {

    &.section-specialty {
        background: #f5f5f5;
    }

    &.section-outstanding-doctor {
        background: #f5f5f5;

    }
    &.section-about{
        border-bottom: 1px solid #f5f5f5;
        border-top: 1px solid #f5f5f5;

        height: 500px !important;
    }

    .section-body {

        .section-customize {
            .bg-image {
                width: 278px;
                height: 150px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                background-color: #eee;

                &.section-specialty {
                    // background-image: url('../../assets/specialty/bac-si-gia-dinh.jpg');

                }

                &.section-medical-facility {
                    

                }

                &.section-outstanding-doctor {
                    // background-image: url('../../assets/outstanding-doctor/bsckii-tran-minh-khuyen.jpg');
                    width: 120px;
                    height: 120px;
                    border-radius: 50%;

                }

                &.section-handbook {
                    background-image: url('../../assets/handbook/quy-trinh-nieng-rang-lac-viet.jpg');
                 

                }
            }
        }




    }

}

.section-share {
    height: 330px;

    .section-container {
        margin: 0 60px;
        display: flex;
        flex-direction: column;

        .section-header {
            height: 60px;
            margin-bottom: 30px;
            padding-top: 30px;

            .title-section {
                font-size: 22px;
                font-weight: 600;
            }

            .btn-section {
                float: right;
                padding: 10px 15px;
                border: none;
                outline: none;
                display: block;
                background: #ebebeb;
                text-transform: uppercase;
                cursor: pointer;

                &:hover {
                    color: #fff;
                    background: #f7d800;
                }
            }
        }



    }
}

.section-outstanding-doctor {
    .outer-bg {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
    }
    .section-customize{
        padding: 5px;
        .customize-border{
            border: 1px solid red;
            padding: 20px;
            cursor: pointer;
        }

    }
}
 .home-footer{
    text-align: center;
    padding-top: 10px;
    a{
        text-decoration: unset;
    }
 }
 .section-about{
    padding: 0 100px;
    .section-about-header{
        padding: 20px 10px;
        font-size: 22px;
        font-weight: 600;
    }
 }
 .section-about-content{
    display: flex;
    gap:15px;
    width:100%;
    .content-left{
        width: 60%;
    }
    .content-right{
        width: 40%;
        p{
            text-align: justify;
            font-style: italic;
            padding-top: 15px;
        }
    }
 }