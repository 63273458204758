@import "../../styles/common.scss";

.header-container {
    z-index: 99;
    display: flex;
    justify-content: space-between;
    background-color: $colormain;
    color: $common-text-color;
    height: 40px;
    position: relative;
    .languages{
        .Welcome{
            margin-right: 10px;
        }
        .language-en{
            margin-left: 10px;
            margin-right: 15px;
            cursor: pointer;
            opacity: 0.6;
            &.active{
                opacity: 1;
                color: greenyellow;
            }
        }
        .language-vi{
            cursor: pointer;
            opacity: 0.6;
            &.active{
                opacity: 1;
                color:red;
            }
        }
        .btn-logout {
            color: $common-text-color;
            line-height: 40px;
            height: 40px;
            padding: 0 10px;
            &:hover {
                background-color: darken($colormain, 5);
            }
            i {
                font-size: $base-size + 1px;
            }
        }
    }
  
}